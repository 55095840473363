import React, { useEffect } from 'react'
import { useTheme } from '@mui/material/styles';

import Grid from '@mui/material/Unstable_Grid2';
import Paper from '@mui/material/Paper';

import {
  Header
} from '../../vaishno/dist';

import {
  useBookmarks
} from '../../vaishno/dist/hooks';

import {
  bookmarkAdd,
  bookmarkDelete,
  performShare
} from '../../vaishno/dist/lib';

import {
  selectedMarket,
  selectedVariety,
  selectedFilter,
  selectedBookmarks,
  bookmark,
  deleteBookmark,
  changeTheme,
  changeMarket,
  changeVariety,
  selectedDates
} from '../../vaishno/dist/slices';



import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { useNavigate } from 'react-router-dom';

import {
  usePrices
} from '../../vaishno/dist/hooks';

export const Heading = ({isHome = false}) => {

  const bookmarks = useBookmarks();

  const navigate = useNavigate()

  const theme = useTheme()

  const dispatch = useDispatch()
  // const bookmarks = useSelector(selectedBookmarks)

  const market = useSelector(selectedMarket)
  const variety = useSelector(selectedVariety);
  const { t } = useTranslation()

  

  const dates = useSelector(selectedDates);

  const prices = usePrices(market, variety, dates.start, dates.end)

  useEffect(() => {
    document.body.style.backgroundColor = theme.palette.background.paper;
  }, [theme])    
  
  const handleBookmark = async () => {
    if (!bookmarks.find((item) => item.market === market && item.variety === variety)) {
      // dispatch(bookmark({market, variety}))
      await bookmarkAdd(market, variety)
    }
    else {
      const bm = bookmarks.find((item) => item.market === market && item.variety === variety)
      bookmarkDelete(bm)
      // dispatch(deleteBookmark(bm.id))
    }
  }

  const handleShare = () => {
    performShare(prices, market, variety, null, t)
  }

  const gotoBookmark = (bm) => {
    dispatch(changeMarket(bm.market))
    dispatch(changeVariety(bm.variety))
    navigate(`/${bm.market}/${bm.variety}`)
  }

  const toggleTheme = () => {
    // if (isHome) {
    //   if (theme.name === 'light') {
    //     document.querySelector('.recharts-wrapper').style.background = '#121212'
    //   }
    //   else {
    //     document.querySelector('.recharts-wrapper').style.background = 'none'
    //   }       
    // }    
    dispatch(changeTheme(theme.name === 'light' ? 'dark' : 'light'))    
  }

  return (
    <Header
      prices={prices}
      title={t('title')}
      onBookmarkEdit={handleBookmark}
      onBookmarkClick={(bm) => gotoBookmark(bm)}
      onThemeToggle={toggleTheme}
      market={market}
      variety={variety}
      isHome={isHome}
      bookmarks={bookmarks || []}
      onShare={handleShare}
      languages={t('supportedLocales', { returnObjects: true})} />    
  )
}
