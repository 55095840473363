"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.selectedBookmarks = exports.deleteBookmark = exports.default = exports.bookmark = void 0;
var _toolkit = require("@reduxjs/toolkit");
var initialState = {
  bookmarks: []
};
var bookmarkSlice = (0, _toolkit.createSlice)({
  name: 'bookmarks',
  initialState: initialState,
  reducers: {
    bookmark: function bookmark(state, _ref) {
      var payload = _ref.payload;
      state.bookmarks.push({
        id: "".concat(payload.market.toLowerCase(), ":").concat(payload.variety.toLowerCase()),
        market: payload.market,
        variety: payload.variety
      });
      state.bookmarks = state.bookmarks.sort(function (a, b) {
        return a.market.localeCompare(b.market);
      });
    },
    deleteBookmark: function deleteBookmark(state, _ref2) {
      var payload = _ref2.payload;
      var index = state.bookmarks.findIndex(function (item) {
        return item.id === payload;
      });
      state.bookmarks.splice(index, 1);
    }
  }
});

// NOTE: Change name
var selectedBookmarks = function selectedBookmarks(state) {
  return state.bookmarks.bookmarks;
};
exports.selectedBookmarks = selectedBookmarks;
var _bookmarkSlice$action = bookmarkSlice.actions,
  bookmark = _bookmarkSlice$action.bookmark,
  deleteBookmark = _bookmarkSlice$action.deleteBookmark;
exports.deleteBookmark = deleteBookmark;
exports.bookmark = bookmark;
var _default = bookmarkSlice.reducer;
exports.default = _default;