"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.lightTheme = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/esm/defineProperty"));
var _objectSpread2 = _interopRequireDefault(require("@babel/runtime/helpers/esm/objectSpread2"));
var _styles = require("@mui/material/styles");
var _colors = require("@mui/material/colors");
var _defaultTheme = _interopRequireDefault(require("@mui/material/styles/defaultTheme"));
var lightTheme = (0, _styles.createTheme)({
  name: 'light',
  palette: (0, _objectSpread2.default)((0, _objectSpread2.default)({
    mode: 'light'
  }, _defaultTheme.default.palette), {}, {
    primary: {
      main: '#2196f3',
      light: '#6ec6ff',
      dark: '#0069c0'
    },
    secondary: {
      main: '#d4e157',
      light: '#ffff89',
      dark: '#a0af22'
    },
    chartContainerBackground: _defaultTheme.default.palette.background.paper,
    chartContainerShadow: 'none'
  }),
  transitions: (0, _objectSpread2.default)({}, _defaultTheme.default.transitions),
  typography: {
    fontFamily: ['Roboto'].join(','),
    button: {
      textTransform: 'none'
    }
  },
  shape: {
    borderRadius: 0
  },
  components: {
    MuiFab: {
      styleOverrides: {
        root: {
          background: "linear-gradient(to bottom right, #d4e157, #a0af22)"
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: function root(_ref) {
          var ownerState = _ref.ownerState;
          if (ownerState.component === 'header') {
            return (0, _defineProperty2.default)({
              background: "linear-gradient(to top left, #0069c0, #2196f3)",
              top: 0,
              height: '56px',
              justifyContent: 'center'
            }, _defaultTheme.default.breakpoints.down('sm'), {
              height: '56px'
            });
          } else if (ownerState.component === 'footer') {
            return (0, _defineProperty2.default)({
              background: "linear-gradient(to top left, #0069c0, #2196f3)",
              top: 'auto',
              width: '100%',
              bottom: 0,
              textAlign: 'center',
              height: '56px'
            }, _defaultTheme.default.breakpoints.down('sm'), {
              height: '56px'
            });
          }
        }
      }
    },
    MuiToolbar: {
      styleOverrides: {
        root: function root(_ref4) {
          var ownerState = _ref4.ownerState;
          if (ownerState.className === 'footer') {
            return {
              justifyContent: 'center'
            };
          } else if (ownerState.className === 'header') {
            return {
              justifyContent: 'space-between',
              pr: 1,
              // FIXME: Somehow padding left is not working
              borderLeft: '8px solid transparent !important'
            };
          }
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: function root(_ref5) {
          var ownerState = _ref5.ownerState;
          ownerState.size = 'large';
        }
      }
    },
    MuiMenu: {
      styleOverrides: {}
    },
    MuiList: {
      defaultProps: {
        disablePadding: false
      },
      styleOverrides: {
        root: function root(_ref6) {
          var ownerState = _ref6.ownerState;
          if (ownerState.className === 'data-list') {
            return {
              disablePadding: false,
              marginBottom: '50px !important'
            };
          }
        }
      }
    },
    MuiListItem: {
      styleOverrides: {
        root: function root(_ref7) {
          var ownerState = _ref7.ownerState;
          if (ownerState.className === 'data-list-item') {
            return {
              display: 'list-item',
              minHeight: '50px',
              background: _defaultTheme.default.palette.background.paper
            };
          } else {
            return {
              display: 'list-item',
              background: _defaultTheme.default.palette.background.paper
            };
          }
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: function root(_ref8) {
          var ownerState = _ref8.ownerState;
          if (ownerState.className === 'card-for-selection') {
            return {
              background: _defaultTheme.default.palette.background.paper,
              boxShadow: 'none',
              overflowY: 'auto'
            };
          } else if (ownerState.className === 'card-for-list') {
            return {
              background: _defaultTheme.default.palette.background.paper
            };
          } else if (ownerState.className === 'card-for-selection-title') {
            return {
              background: "linear-gradient(to bottom right, #d4e157, #a0af22)",
              minHeight: '32px',
              display: 'flex',
              alignItems: 'center'
            };
          } else {
            return {
              background: _defaultTheme.default.palette.background.paper
            };
          }
        }
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          paddingBottom: '16px !important'
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          height: '2rem',
          fontSize: '.9rem'
        },
        filled: {
          background: "linear-gradient(180deg, #2196f3, #0069c0)"
        },
        outlined: {
          border: "2px solid #0069c0"
        }
      }
    },
    MuiListItemButton: {
      defaultProps: {
        alignItems: 'center',
        divider: false,
        disableGutters: true
      }
    },
    MuiListItemText: {
      styleOverrides: {
        root: (0, _defineProperty2.default)({
          textAlign: 'left',
          marginTop: 0,
          marginBottom: 0
        }, _defaultTheme.default.breakpoints.not('xs'), {
          textAlign: 'center'
        })
      }
    },
    MuiListItemIcon: {
      defaultProps: {}
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontWeight: 'bold',
          color: _defaultTheme.default.palette.text.primary
        },
        root: (0, _defineProperty2.default)({}, _defaultTheme.default.breakpoints.only('xs'), {
          fontSize: '12px'
        })
      }
    },
    MuiTypography: {
      styleOverrides: {
        body1: (0, _defineProperty2.default)({}, _defaultTheme.default.breakpoints.only('xs'), {
          fontSize: '12px'
        })
      }
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          display: 'list-item',
          paddingLeft: '8px',
          paddingRight: '8px',
          paddingTop: '4px',
          paddingBottom: '4px'
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: function root(_ref9) {
          var ownerState = _ref9.ownerState;
          if (ownerState.className === 'chart-container') {
            var _ref10;
            return _ref10 = {}, (0, _defineProperty2.default)(_ref10, _defaultTheme.default.breakpoints.up('md'), {
              height: '450px'
            }), (0, _defineProperty2.default)(_ref10, _defaultTheme.default.breakpoints.up('sm'), {
              height: '400px'
            }), (0, _defineProperty2.default)(_ref10, _defaultTheme.default.breakpoints.up('xs'), {
              height: '300px'
            }), _ref10;
          } else if (ownerState.className === 'filter-container') {
            ownerState.sx.background = "linear-gradient(to top left, #d4e157, #a0af22)";
            ownerState.sx.minimumHeight = '32px';
            if (document.querySelector(".".concat(ownerState.className))) {
              document.querySelector(".".concat(ownerState.className)).children[0].style.justifyContent = 'space-between';
            }
          }
        }
      }
    }
  }
});
exports.lightTheme = lightTheme;