import React, { useCallback, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';

import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';
import a11yChecker from "a11y-checker";

import {
  Selection,
  Navigation,
  Footer,
  Loading,
  Onboarding
} from '../../vaishno/dist';

import { Heading } from '../heading/heading';


import { useNavigate } from 'react-router-dom';

import {
  useMarkets,
  useVarieties
} from '../../vaishno/dist/hooks';

import {
  selectedMarket,
  selectedVariety,
  changeMarket,
  changeVariety
} from '../../vaishno/dist/slices';


import {
  useSelector,
  useDispatch
} from 'react-redux';

export const Market = () => {

  useEffect(() => {
    a11yChecker()
  }, [])

  const navigate = useNavigate()

  const { t } = useTranslation();

  const theme = useTheme()
  
  const dispatch = useDispatch()
  const market = useSelector(selectedMarket);
  const variety = useSelector(selectedVariety)
  const markets = useMarkets()
  const varieties = useVarieties(market)  

  const marketRef = useCallback((node) => {
    if (node) {
      const header = document.querySelector('header');
      node.style.marginTop = `${header.clientHeight}px`
    }
  }, [])

  const varietyRef = useCallback((node) => {
    if (node) {
      const footer = document.querySelector('footer')
      const height = document.body.clientHeight - node.offsetTop - footer.clientHeight;
      node.style.height = `${height}px`
    }
  }, [])

  // TODO: Use loading component instead
  if(!markets || markets.length === 0) {
    return (
      <Loading message="please wait while we loading the data" />
    )
  }  
  return (
    <>
      <Onboarding isHome={false} />
      <Navigation title="done" type="done" onClick={() => navigate(`/${market}/${variety}`)}/>
      <Heading />
      <Grid container sx={{height: '100%'}}>
        <Grid xs={12} md={12} ref={marketRef} sx={{height: '100%'}}>
          <Selection
            id="market"
            title="select market"
            data={markets}
            selected={market}
            onChange={(v) => dispatch(changeMarket(v))}
          />
        </Grid>
        <Grid xs={12} md={12} ref={varietyRef}>
          <Selection
            id="selection"
            title="select variety"
            data={varieties || []}
            selected={variety}
            onChange={(v) => dispatch(changeVariety(v))}
          />
        </Grid>
      </Grid>
      <Footer title={`${t('market')}: ${t(market)} ${variety ? ',' + t('variety') + ':' + t(variety) : ''}`} />
    </>
  )
}
