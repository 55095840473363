"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useVarieties = void 0;
var _react = _interopRequireDefault(require("react"));
var _dexieReactHooks = require("dexie-react-hooks");
var _database = require("../database");
var useVarieties = function useVarieties(market) {
  var varieties = (0, _dexieReactHooks.useLiveQuery)(function () {
    return _database.database.varieties.where('market').equals(market || '').toArray();
  }, [market]);
  return varieties;
};
exports.useVarieties = useVarieties;