"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.selectedFilter = exports.selectedDates = exports.default = exports.changeFilter = void 0;
var _toolkit = require("@reduxjs/toolkit");
var initialState = {
  selected: 'default',
  dates: {
    start: new Date(new Date().setDate(new Date().getDate() - 60)).toISOString().split('T').at(0),
    end: new Date().toISOString().split('T').at(0)
  }
};
var filterSlice = (0, _toolkit.createSlice)({
  name: 'filter',
  initialState: initialState,
  reducers: {
    changeFilter: function changeFilter(state, _ref) {
      var payload = _ref.payload;
      var start, end;
      switch (payload) {
        case 'week':
          end = new Date().toISOString().split('T').at(0);
          start = new Date(new Date().setDate(new Date().getDate() - 7)).toISOString().split('T').at(0);
          state.selected = 'week';
          break;
        case 'forthnight':
          end = new Date().toISOString().split('T').at(0);
          start = new Date(new Date().setDate(new Date().getDate() - 15)).toISOString().split('T').at(0);
          state.selected = 'forthnight';
          break;
        case 'month':
          end = new Date().toISOString().split('T').at(0);
          start = new Date(new Date().setDate(new Date().getDate() - 30)).toISOString().split('T').at(0);
          state.selected = 'month';
          break;
        default:
          end = new Date().toISOString().split('T').at(0);
          start = new Date(new Date().setDate(new Date().getDate() - 60)).toISOString().split('T').at(0);
          state.selected = 'default';
      }
      state.dates = {
        start: start,
        end: end
      };
    }
  }
});
var selectedFilter = function selectedFilter(state) {
  return state.filter.selected;
};
exports.selectedFilter = selectedFilter;
var selectedDates = function selectedDates(state) {
  return state.filter.dates;
};
exports.selectedDates = selectedDates;
var changeFilter = filterSlice.actions.changeFilter;
exports.changeFilter = changeFilter;
var _default = filterSlice.reducer;
exports.default = _default;