"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useMarkets = void 0;
var _react = _interopRequireDefault(require("react"));
var _dexieReactHooks = require("dexie-react-hooks");
var _database = require("../database");
var useMarkets = function useMarkets() {
  var markets = (0, _dexieReactHooks.useLiveQuery)(function () {
    return _database.database.markets.toArray();
  });
  return markets;
};
exports.useMarkets = useMarkets;