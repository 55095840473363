import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { store, persistor} from './app/index';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';


import { PersistGate } from 'reduxjs-toolkit-persist/integration/react'

import './index.css';
console.log(persistor)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>        
    <Provider store={store}>            
      <App />
    </Provider>        
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// <PersistGate loading={null} persistor={persistor}>        
