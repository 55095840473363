"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _useMarkets = require("./use-markets");
Object.keys(_useMarkets).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _useMarkets[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _useMarkets[key];
    }
  });
});
var _useVarieties = require("./use-varieties");
Object.keys(_useVarieties).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _useVarieties[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _useVarieties[key];
    }
  });
});
var _usePrices = require("./use-prices");
Object.keys(_usePrices).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _usePrices[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _usePrices[key];
    }
  });
});
var _useBookmarks = require("./use-bookmarks");
Object.keys(_useBookmarks).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _useBookmarks[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _useBookmarks[key];
    }
  });
});
var _useWindowsize = require("./use-windowsize");
Object.keys(_useWindowsize).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _useWindowsize[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _useWindowsize[key];
    }
  });
});
var _useMessages = require("./use-messages");
Object.keys(_useMessages).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _useMessages[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _useMessages[key];
    }
  });
});