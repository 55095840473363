import { configureStore, combineReducers } from '@reduxjs/toolkit';

import { persistReducer, persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'reduxjs-toolkit-persist';
import storage from 'reduxjs-toolkit-persist/lib/storage' // defaults to localStorage for web

import bookmarkSlice from '../vaishno/dist/slices/bookmark';
import themeSlice from '../vaishno/dist/slices/theme';
import filterSlice from '../vaishno/dist/slices/filter';
import selectionSlice from '../vaishno/dist/slices/selection';

const reducers = combineReducers({
  selection: selectionSlice,
  bookmarks: bookmarkSlice,
  theme: themeSlice,
  filter: filterSlice
});

const persistConfig = {
  key: "root",
  storage: storage
};


const persistedReducer = persistReducer(persistConfig, reducers)

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          FLUSH,
          REHYDRATE,
          PAUSE,
          PERSIST,
          PURGE,
          REGISTER
        ]
      }
    })
  }
})

const persistor = persistStore(store)

export { persistor, store }
