"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useBookmarks = void 0;
var _react = _interopRequireDefault(require("react"));
var _dexieReactHooks = require("dexie-react-hooks");
var _database = require("../database");
var useBookmarks = function useBookmarks() {
  return (0, _dexieReactHooks.useLiveQuery)(function () {
    return _database.database.bookmarks.toArray();
  }, []);
};
exports.useBookmarks = useBookmarks;