import React,  { useState, useEffect } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';

import a11yChecker from "a11y-checker";




import {
  Filter,
  LineChart,
  List,
  Navigation,
  Footer,
  Loading,  
  Onboarding
} from '../../vaishno/dist';

import {
  usePrices,
  useMarkets,
  useVarieties,
  useWindowSize,
  useMessages
} from '../../vaishno/dist/hooks';

import {
  selectedMarket,
  selectedVariety,
  selectedFilter,
  changeFilter,
  changeMarket,
  changeVariety,
  selectedDates
} from '../../vaishno/dist/slices';


import {
  useSelector,
  useDispatch
} from 'react-redux';


import { Heading } from '../heading/heading';

import { useTranslation } from 'react-i18next';

import { useNavigate, useParams } from 'react-router-dom';


export const Home = () => {

  const [ offsetHeader, setOffsetHeader ] = useState(0)
  const [ offsetList, setOffsetList ] = useState(0)  

  
  const size = useWindowSize()
  
  const params = useParams();
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    if (params.market)
      dispatch(changeMarket(params.market))
    if (params.variety)
      dispatch(changeVariety(params.variety))

    const targetNode = document.querySelector('body');

    // Options for the observer (which mutations to observe)
    const config = { attributes: true, childList: true, subtree: true };

    // Callback function to execute when mutations are observed
    let count = 0;
    const callback = (mutationList, observer) => {      
      for (const mutation of mutationList) {
        if (mutation.type === 'childList') {
          if (Array.from(mutation.addedNodes).find((e) => e.localName === 'header')) {
            setOffsetHeader(Array.from(mutation.addedNodes).find((e) => e.localName === 'header').clientHeight)
            count++;
          }
          else {
            if (mutation.addedNodes) {
              mutation.addedNodes.forEach((e) => {
                if (e.classList) {
                  if (Array.from(e.classList).find((e) => e === 'header-container')) {
                    count++;
                    setOffsetList(e.clientHeight)
                  }                  
                }
              })
              if (count >= 3) {
                observer.disconnect();
              } 
            }
          }
        }
      }
    };

    // Create an observer instance linked to the callback function
    const observer = new MutationObserver(callback);

    // Start observing the target node for configured mutations
    observer.observe(targetNode, config);
    a11yChecker();
    // Later, you can stop observing
    // observer.disconnect();
  }, []);

  const dates = useSelector(selectedDates);

  const { t } = useTranslation();

  const market = useSelector(selectedMarket);
  const markets = useMarkets();
  const varieties = useVarieties(market)
  const variety = useSelector(selectedVariety);  
  const prices = usePrices(market, variety, dates.start, dates.end)
  
  const filter = useSelector(selectedFilter);

  const message = useMessages(5, market, variety, filter)  
  
  if(!prices) {
    return (
      <Loading message="please wait while we loading the data" />
    )
  }

  if (prices.length === 0) {
    if (!varieties.find((item) => item.id === variety)) {
      const url = `/${varieties.at(0).market}/${varieties.at(0).id}`;
      window.location = url  
    }
    else {
      // Reset filter
      dispatch(changeFilter('default'))
      navigate('/')
    }
  }
  
  const applyFilter = (v) => {
    dispatch(changeFilter(v))
  }

  // FIXME: Need to update the url
  const onNext = () => {    
    const currentMarketIdx = markets.findIndex((item) => item.id === market)
    const nextMarket = markets.at(currentMarketIdx + 1) || markets.at(0)
    const varNames = varieties.filter((item) => item.market === nextMarket.id)
    if (varNames.length <= 1) {
      dispatch(changeMarket(nextMarket.id))
    }
  }

  // FIXME: Need to update the url
  const onPrev = () => {
    if (varieties.length <= 1) {
      const currentMarketIdx = markets.findIndex((item) => item.id === market)
      const previousMarket = markets.at(currentMarketIdx - 1) || markets.at(-1)
      const varNames = varieties.filter((item) => item.market === previousMarket.id)
      if (varNames.length <= 1) {
        dispatch(changeMarket(previousMarket.id))
      }      
    }    
  }

  const title = `${market} - ${variety}`
  
  return (
    <>
      {
        prices && prices.length > 1 &&
          (
            <>
              <Onboarding isHome={true} />
              <Navigation title="choose" type="choose" onClick={() => navigate('/')}/>
              <Heading isHome={true}/>
              <Box sx={{position: 'sticky', top: `${offsetHeader}px`, bottom: 'auto', width: '100%', zIndex: 1000}} className="header-container">
                <Filter
                  title={title}
                  view={filter}
                  onChange={(v) => applyFilter(v)}
                  onNext={onNext}
                  onPrevious={onPrev}
                />
                <Paper className="chart-container" style={{backgroundImage: 'none'}}>
                  <LineChart data={prices} />
                </Paper>
              </Box>
              <Grid container className="list-container" sx={{mt: `${offsetHeader}px`}}>
                {
                  prices && 
                    <Grid xs={12} md={12}>
                      <List data={prices.slice().reverse()} />
                    </Grid>
                }
              </Grid>
              <Footer title={message} />
            </>            
          )
      }
    </>
  )
}
