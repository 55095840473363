"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.defaultTheme = void 0;
var _objectSpread2 = _interopRequireDefault(require("@babel/runtime/helpers/esm/objectSpread2"));
var _styles = require("@mui/material/styles");
var _defaultTheme = _interopRequireDefault(require("@mui/material/styles/defaultTheme"));
var defaultTheme = (0, _styles.createTheme)({
  palette: (0, _objectSpread2.default)({
    mode: 'dark'
  }, _defaultTheme.default.palette),
  transitions: (0, _objectSpread2.default)({}, _defaultTheme.default.transitions),
  typography: {
    fontFamily: 'Nunito Sans, sans-serif',
    button: {
      textTransform: 'none'
    }
  },
  shape: {
    borderRadius: 3
  }
});
exports.defaultTheme = defaultTheme;